<template>
  <v-card>
    <v-card-title class="d-flex justify-center">Mijn facturen</v-card-title>
    <v-data-table
      :items="invoices"
      :headers="headers"
      :server-items-length="invoicesLength"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, 100]
      }"
      item-key="_id"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label :color="invoiceStatuses[item.status].color">{{
          invoiceStatuses[item.status].text
        }}</v-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ toDateString(item.date) }}
      </template>
      <template v-slot:[`item.grossTotal`]="{ item }">
       {{toCurrency(item.grossTotal)}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { toDateString, toCurrency } from "../js/functions"
export default {
  name: "myInvoices",
  data() {
    return {
      invoicesLength: 0,
      headers: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Totaal", value: "grossTotal" }
      ],
      options: {
        itemsPerPage: 25,
        page: 1,
        sortBy: ["invoiceNumber"],
        sortDesc: [true]
      }
    }
  },
  methods: {
    toDateString(date) {
      return toDateString(date)
    },
    toCurrency(number) {
      return toCurrency(number)
    },
    async fetchInvoices() {
      try {
        let response = await this.$store.dispatch("fetchGET", {
          type: "clientInvoices",
          data: {
            clientNumber: this.$store.state.client.clientNumber,
            options: this.options
          }
        })
        console.log(response)
        this.invoicesLength = response[0].length.length
          ? response[0].length[0].length
          : 0
      } catch (e) {
        console.error(e)
      }
    }
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchInvoices()
      }
    }
  },
  computed: {
    invoices() {
      return this.$store.state.invoices
    },
    invoiceStatuses() {
      return this.$store.state.settings.invoiceStatuses
    }
  },
  mounted() {
    this.fetchInvoices()
  }
}
</script>

<style scoped>
.v-application .red, .v-application .orange, .v-application .light-green, .v-application .black{
  color: white;
  border-color: #013FBA !important;
}
::v-deep .v-badge__badge{ 
  color: black !important;
}
</style>